/* TailoredMindfulness.css */

/* Google Fonts Import */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  transition: all 0.3s ease-in-out; /* Applies to all properties */
}


body {
  font-family: 'Inter', sans-serif;
  background-color: #f5f5f5;
  color: #333;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: transparent;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: 600;
  color: #333;
}

.input {
  width: 100%;
  max-width: 400px;
  padding: 12px 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 20px;
  transition: color 0.3s ease-in-out;
  color: black;
}

.input::placeholder {
  color: #aaa;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.input.fading::placeholder {
  opacity: 0; /* Fade out placeholder */
}

.suggestion-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.suggestion-button {
  padding: 10px 15px;
  font-size: 0.9rem;
  border-radius: 50%;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background 0.3s, border-color 0.3s;
}

.suggestion-button:hover {
  background-color: #f0f0f0;
  border-color: #bbb;
}

.slider-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.label {
  margin-bottom: 15px;
  font-size: 1rem;
  color: #555;
}

.slider {
  width: 100%;
}

.generate-button {
  padding: 12px 25px;
  font-size: 1rem;
  background-color: #4CAF50; /* Average green */
  color: #fff; /* White text */
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 40px;
  transition: background 0.3s;
}

.generate-button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}


.audio {
  width: 80%;
  max-width: 400px;
  margin-top: 20px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.popup button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4285F4;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  margin-top: 20px;
}

.google-logo {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.container .icon-button {
    font-size: 2em; /* Double the size */
    background: none; /* Remove background */
    border: none; /* Remove border */
    color: inherit; /* Inherit text color */
    cursor: pointer; /* Pointer cursor on hover */
    position: relative; /* Position relative for floating */
    opacity: 0.5; /* Transparent effect */
    transition: opacity 0.3s; /* Smooth transition for hover effect */
}

.container .icon-button:hover {
    opacity: 1; /* Full opacity on hover */
}

